import axios from 'axios';

export default class ReportesService {

    getAvanceListadoInformacion() {            
        const url = process.env.VUE_APP_APIURL + `/api/reportesotestudioslistadosinformacion`;    
        return axios.get(url).then(res=>res.data);
    }

    getAvanceListadoInformacionKPI() {            
        const url = process.env.VUE_APP_APIURL + `/api/reportesotestudioslistadosinformacionkpi`;    
        return axios.get(url).then(res=>res.data);
    }

     
    getReporteAlertas(info){
        const url = process.env.VUE_APP_APIURL + `/api/reportealertas`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getReporteAlertasEnviado() {            
        const url = process.env.VUE_APP_APIURL + `/api/reportealertasenviado`;    
        return axios.get(url).then(res=>res.data);
    }

    updateReporteAlertasEnviado(info){
        const url = process.env.VUE_APP_APIURL + `/api/reportealertasedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    EnviaAlertas(info){
        const url = process.env.VUE_APP_APIURL + `/api/reporte`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getReporteAvanceGeneral(info){       
         const url = process.env.VUE_APP_APIURL + `/api/reporteavancegeneral`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getReporteEspecifico(info){
        const url = process.env.VUE_APP_APIURL + `/api/reporteavanceespecifico`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getReporteEspecificoHistorico(info){
        const url = process.env.VUE_APP_APIURL + `/api/reporteavanceespecificohistorico`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    getReporteECA(info){
        const url = process.env.VUE_APP_APIURL + `/api/reporteeca`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

}